import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: '首页',
    component: Home
  },
  {
    path: '/cpjjfa',
    name: '首页',
    redirect:'/cpjjfa/zhfw'
  },
  {
    path: '/cpjjfa',
    name: '产品解决方案',
    component: () => import( '../views/cpjjfa/index.vue'),
    children:[
      {
        path: '/cpjjfa/zhfw',
        name: '智慧法务',
        component: () => import( '../views/cpjjfa/zhfw.vue'),
    },
    {
      path: '/cpjjfa/zhcg',
      name: '智慧采购',
      component: () => import( '../views/cpjjfa/zhcg.vue'),
  },
    {
        path: '/cpjjfa/zhrz',
        name: '智慧人资',
        component: () => import( '../views/cpjjfa/zhrz.vue'),
    },
    {
        path: '/cpjjfa/zhsj',
        name: '智慧审计',
        component: () => import( '../views/cpjjfa/zhsj.vue'),
    },
    {
        path: '/cpjjfa/bgxt',
        name: '办公协同',
        component: () => import( '../views/cpjjfa/bgxt.vue'),
    },
    {
        path: '/cpjjfa/dsj',
        name: '大数据',
        component: () => import( '../views/cpjjfa/dsj.vue'),
    },
  ]
  },
  {
      path: '/jszc',
      name: '技术支持',
      component: () => import( '../views/jszc/index.vue'),  
  },
  {
    path: '/yhal',
    name: '用户案列',
    component: () => import( '../views/yhal/index.vue'),  
  },
  {
    path: '/yhal/zkd',
    name: '中科大',
    component: () => import( '../views/yhal/components/zkd.vue'),  
  },
  {
    path: '/yhal/dlsjy',
    name: '电力设计',
    component: () => import( '../views/yhal/components/dlsjy.vue'),  
  },
  {
    path: '/yhal/csgx',
    name: '城市更新',
    component: () => import( '../views/yhal/components/csgx.vue'),  
  },
  {
    path: '/yhal/gyyq',
    name: '工业引擎',
    component: () => import( '../views/yhal/components/gyyq.vue'),  
  },
  {
    path: '/yhal/gdyh',
    name: '光大银行',
    component: () => import( '../views/yhal/components/gdyh.vue'),  
  },
  {
    path: '/yhal/jkjt',
    name: '交控集团',
    component: () => import( '../views/yhal/components/jkjt.vue'),  
  },
  {
    path: '/gywm',
    name: '关于我们',
    component: () => import( '../views/gywm/index.vue'),  
  },
  {
    path: '/gywm/more',
    name: '关于我们-更多',
    component: () => import( '../views/gywm/component/more.vue'),  
  }
]

const router = new VueRouter({
  // mode:'hash',
  routes
})

export default router
