<template>
  <div class="">
    <!-- 首页导航 -->
    <div class="indeximg">
      <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li @click="go('/home')"><span class="active ">首页</span></li>
            <li class="indexli2 ">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li class="" @click="go('/jszc')"><span>技术支持</span></li>
            <li class="" @click="go('/yhal')"><span>用户案例</span></li>
            <li class="" @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
        </div>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src=".././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
      <div class="fangan"><img src=".././assets/fangan.png" alt=""></div>
      <el-carousel height="800" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index) in indexback" :key="index">
          <img :src="item.path" alt="">
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li @click="go('/home')"><span class="active ">首页</span></li>
            <li class="indexli2 ">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li class="" @click="go('/jszc')"><span>技术支持</span></li>
            <li class="" @click="go('/yhal')"><span>用户案例</span></li>
            <li class="" @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
        </div>
      </div> -->
    </div>
    <!-- 核心优势 -->
    <div class="hxys">
      <div class="hxyscontent">
        <div class="hxystitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">核心优势</div>
          <div class="hxystitleback2"></div>
        </div>
        <!--  -->
       <div class="hxysbottom">
         <div class="hxysbottomevery">
           <div><img src=".././assets/hxys1.png" alt=""></div>
           <div class="hxysbottomeveryright">
             <p>丰富的行业经验</p>
             <div> 我司是以研发型主导、面向客户定制的专业软硬件产业解决方案供应商。产品种类丰富，并和各行各业的合作伙伴共同成长，积累了大量的行业成功案例。公司在国企数字化转型、政府服务领域多年的行业经验与创新技术，冥定了行业领先地位，积累了自身的核心竞争优势，具有丰富的行业经验和全方位、多层次服务能力。</div>
           </div>
         </div>
         <div class="hxysbottomevery">
           <div><img src=".././assets/hxys2.png" alt=""></div>
           <div class="hxysbottomeveryright">
             <p>专业的开发团队</p>
             <div> 核心团队成员来自国内外知名ICT公司，拥有丰富的技术储备、行业认知、服务经验。全体员工本科及以上学位，曾就职于百度、腾讯、思科、讯飞、华为等知名ICT企业，具有丰富的大数据平台设计、开发、建模经验。</div>
           </div>
         </div>
         <div class="hxysbottomevery">
           <div><img src=".././assets/hxys3.png" alt=""></div>
           <div class="hxysbottomeveryright">
             <p>完善的售后体系</p>
             <div> 用户均有专人负责，从咨询、签约、实施、到售后全程业务服务，长期跟踪，保持联系，给用户全面的服务，确保用户的IT投资保值，增值。</div>
           </div>
         </div>
       </div>
      </div>
    </div>
    <!-- 产品解决方案 -->
    <cpjjfa-banner></cpjjfa-banner>

    <!-- 行业应用方案 -->
    <!-- <div class="yyfa">
      <div class="yyfacontent">
        <div class="yyfatitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">行业应用方案</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="yyfaimg">
          <div class="yyfaimglie1">
            <div class="yyfaimg1">
              <img src=".././assets/zhizaoye.png" alt="">
              <div class="yyfaimg1title">制造业</div>
              <div class="yyfaimg1mask">
                <div class="yyfaimg5mask">
                  <div class="yyfaimg5masktitle"></div>
                  <div class="yyfaimg5maskcontent"></div>
                </div>
              </div>
              </div>
              <div class="yyfaimg4">
                <img src=".././assets/canying.png" alt="">
                <div class="yyfaimg4title">餐饮</div>
                <div class="yyfaimg4mask"></div>
              </div>
              <div class="yyfaimg7">
                <img src=".././assets/xuexiao.png" alt="">
                <div class="yyfaimg7title">学校</div>
                <div class="yyfaimg7mask"></div>
              </div>
            </div>
          <div class="yyfaimglie2">
             <div class="yyfaimg2">
            <img src=".././assets/gaosugonglu.png" alt="">
            <div class="yyfaimg2title">高速公路</div>
            <div class="yyfaimg2mask"></div>
          </div>
         <div class="yyfaimg5">
            <img src=".././assets/dianli.png" alt="">
            <div class="yyfaimg5title">电力</div>
            <div class="yyfaimg5mask">
              <div class="yyfaimg5masktitle">电力</div>
              <div class="yyfaimg5maskcontent">研发智能服务机器人及智能巡航机器人，提高企业智能化水平</div>
            </div>
          </div>
          <div class="yyfaimg8">
            <img src=".././assets/zhengfujigou.png" alt="">
            <div class="yyfaimg8title">政府机构</div>
            <div class="yyfaimg8mask"></div>
          </div>
          </div>

          <div class="yyfaimglie3">
             <div class="yyfaimg3">
            <img src=".././assets/nengyuan.png" alt="">
            <div class="yyfaimg3title">能源</div>
            <div class="yyfaimg3mask"></div>
          </div>
           <div class="yyfaimg6">
            <img src=".././assets/yinhang.png" alt="">
            <div class="yyfaimg6title">银行</div>
            <div class="yyfaimg6mask"></div>
          </div>
          </div>
          </div>
      </div>
    </div> -->
    <!-- 合作伙伴 --> 
    <div class="hzhb">
      <div class="hzhbcontent">
        <div class="hzhbtitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">合作伙伴</div>
          <div class="hxystitleback2"></div>
        </div>
        <div>
          <img src=".././assets/hzhb.png" alt="">
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <tabar-bottom></tabar-bottom> 
  </div>
</template>

<script>
import CpjjfaBanner from '../components/CpjjfaBanner.vue'
import TabarBottom from '../components/TabarBottom.vue'

  export default {
     metaInfo: {
      title: '中科星图瑞云科技有限公司', // set a title
      meta: [{                 // set meta
        name: '中科星图瑞云',
        content: '中科星图瑞云科技有限公司'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: ''
      }]
    }
,
    data(){
      return{
        index:0,
        indexback:[
            {path:require('.././assets/homebackground.png')},
            {path:require('.././assets/homebackground1.png')},
            {path:require('.././assets/homebackground2.png')}
        ]
      }
    },
    mounted(){
      window.localStorage.setItem('ace',1)
    },
    computed:{

    },
    methods:{
      
      go(path){
        this.$router.push(
          {
            path:path
          }
        )
      },

    },
    components: { TabarBottom, CpjjfaBanner },
  }
</script>

<style lang="scss" scoped>
.enbutton{
  display: inline-block;
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 3.8%;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.fangan{
  position: absolute;
  bottom: 0;
  z-index: 9999;
  margin-left: 50%;
  transform: translateX(-110px);
  margin-bottom: 150px;
}
::v-deep .el-carousel__container{
  height: 800px !important;
  text-align: center;
}
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 800px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
.navcenter{
  text-align: center;
}
.indeximgtopcontent{
  z-index: 9999;
  position: absolute;
  width: 1223px;
  height: 46px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-611.5px);
  display: flex;
  justify-content: space-between;
  // margin-top: 32px;
}
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
// 合作伙伴
.hzhb{

  background: #fff;
  // width: 1903px;
  height: 884px;
  margin: 0 auto;
  .hzhbcontent{
    padding-top: 80px;
      width: 1200px;
      height: inherit;
      margin: 0 auto;
      .hzhbtitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
  }

}
//
.yyfaimglie1,.yyfaimglie2,.yyfaimglie3{
  width: 32%;
  height: 100%;

}
// 行业应用方案
.yyfaimg1:hover .yyfaimg1mask{
          display: block;
        }
.yyfaimg1{

        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg1title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg1mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg2:hover .yyfaimg2mask{
          display: block;
        }
.yyfaimg2{
        width: 386px;
        height: 280px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg2title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg2mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
         .yyfaimg3:hover .yyfaimg3mask{
          display: block;
        }
.yyfaimg3{
        width: 386px;
        height: 320px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg3title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg3mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg4:hover .yyfaimg4mask{
          display: block;
        }
.yyfaimg4{
  margin-top: 10px;
        width: 386px;
        height: 340px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg4title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg4mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg5:hover .yyfaimg5mask{
          display: block;
        }
.yyfaimg5{
  margin-top: 10px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg5title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg5mask{

          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
          .yyfaimg5masktitle{
            width: 48px;
            margin-left: 50%;
            transform: translateX(-24px);
            line-height: 50px;
            font-size: 24px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
          .yyfaimg5maskcontent{
            margin-left: 50%;
            transform: translateX(-140px);
            width: 280px;
            height: 54px;
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
      //
      .yyfaimg6:hover .yyfaimg6mask{
          display: block;
        }
.yyfaimg6{
  margin-top: 7px;
        width: 386px;
        height: 520px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg6title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg6mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
       .yyfaimg7:hover .yyfaimg7mask{
          display: block;
        }
.yyfaimg7{
  margin-top: 19px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg7title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg7mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
       .yyfaimg8:hover .yyfaimg8mask{
          display: block;
        }
.yyfaimg8{
  margin-top: 16px;
        width: 386px;
        height: 302px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg8title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg8mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
.yyfa{
  margin: 0 auto;
  // width: 1903px;
  height: 1153px;
  background: #fff;
  .yyfacontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;

    .yyfaimg{
       display: flex;
    flex-flow: column wrap;
      width: 1200px;
      height: 850px;

      margin: 0 auto;

    }
  }
}
.yyfatitle{
  width: 500px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
//解决方案
.jjfalist{
  width: 1200px;
  height: 43px;

  .jjfalistevery{
    cursor: pointer;
    text-align: center;
    width: 25%;
    float: left;
    height: inherit;
    span{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      display: inline-block;
      line-height: 43px;
    }
    span:hover{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #FFA000;
      display: inline-block;
      line-height: 43px;
      border-bottom: 2px solid #FFA000;
    }
  }
}
.indeximg{
  position: relative;
  // display: flex;
  // padding-top: 34px;
  // width: 1903px;
  height: 800px;
  // margin: 0 auto;
  // background: url('.././assets/homebackground.png') no-repeat center 0;
  // background-size: 1918px 800px;
}
.indexlogo{
  // float: left;
  width: 220px;
  height: 52px;
  // margin-left: 360px;
  // margin-top: 32px;
  background: url('.././assets/logo.png') no-repeat center center;
}

.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  // float: left;
  // margin-left: 282px;
  // margin-top: 41px;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
  // .indexlistevery{
  //   cursor: pointer;
  //   text-align: center;
  //   float: left;
  //   width: 160px;
  //   line-height: 30px;
  //   span{
  //     display: inline-block;
  //   }
  //   span:hover{
  //     color: #FFA000;
  //     border-bottom: 2px solid #FFA000;
  //   }
  // }
}
.hxys{
  // // width: 1903px;
  height: 746px;

  margin: 0 auto;
}
.hxyscontent{
  width: 1200px;
  height: inherit;
  margin: 0 auto;

}
.hxystitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.hxysbottomevery{
  margin-bottom: 86px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .hxysbottomeveryright{
    width: 962px;
    p{
      line-height: 53px;
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
    }
    div{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  }
.jjfa{
    // width: 1903px;
    padding-top: 51px;
    height: 812px;
    margin: 0 auto;
    position: relative;
    background: url('.././assets/cpjjfabackground.png') no-repeat 0;
    background-size: 1903px 812px;
  }
  .jjfacontent{
    width: 1200px;
    height: 747px;
    margin: 0 auto;

  }
 .jjfatitle{
  width: 500px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 47px;
  margin-bottom: 38px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
// 轮播
.jjfalisteveryindurce{
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #282A2E;
}
//
  .box {
    margin-top: 30px;
            width: 1200px;
            height: 600px;

            overflow: hidden;
            cursor: pointer;
        }

        .box ul,
        .box ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .box ul li {
            width: 1200px;
            height: 600px;
            display: none;
        }

        .box ul li  {
          .homebanner1{
            margin-top: 20px;
            margin-left: 50%;
            transform: translate(-507px);
            width: 1014px;
            height: 368px;
          }
          .homebanner2{
            margin-top: 20px;
            margin-left: 50%;
            transform: translate(-600px);
            width: 1200px;
            height: 376px;
          }
          .homebanner3{
            margin-top: 20px;
            margin-left: 50%;
            transform: translate(-600px);
            width: 1200px;
            height: 362px;
          }
          .homebanner4{
            margin-top: 30px;
            margin-left: 50%;
            transform: translate(-600px);
            width: 1200px;
            height: 343px;
          }

        }

        .box ol {
            width: 120px;
            position: absolute;
            left: 50%;
            margin-left: -40px;
            bottom: 20px;
            height: 20px;
            background: #fff;
            display: flex;
            justify-content: space-evenly;
            padding: 10px 0;
            border-radius: 20px;
        }

        .box ol li {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f00;
            border: 1px solid #999;
        }

        .box ol li.current {
            background: #00f;
        }

        .box ul li.current {
            display: block;
        }

        .box .left{
          position: absolute;
            top: 50%;
            left: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }
        .box .right {
           position: absolute;
            top: 50%;
            right: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }

        .left {
            left: 0;
        }

        .right {
            right: 0;
        }
        .jjfalistevery_span{
        color: #FFA000 !important;
         border-bottom:2px solid #FFA000 !important;
}
</style>
