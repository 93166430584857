<template>
  <div id="app">
    <router-view/>
    <div class="hezuo">
      <!-- <div class="hezuotop">
        <div class="hezuotopsqhz"><div class="hezuotopsqhzcontent">申请合作</div></div>
        <div class="hezuotopsqhz2" @click="open"><i class="el-icon-phone"></i></div>
      </div> -->
      <!-- <div @click="backtop" class="hezuobottom">
          <i class="el-icon-top"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
 export default {
  data(){
    return{

    }
  },
  methods:{
    backtop(){
      let top = document.documentElement.scrollTop || document.body.scrollTop
        // 实现滚动效果
        const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
        clearInterval(timeTop)
        }
        }, 10)
    },
      open() {
        const h = this.$createElement;
        this.$msgbox({
          title: '',
          message: h('p', null, [
            h('span', null, '联系电话： '),
            h('i', { style: 'color: teal' }, '0551-65650717')
          ]),
          showCancelButton: false,
          confirmButtonText: '',
          cancelButtonText: '',
          center:true
        }).then(action => {
         
        });
      }
    }
  }
</script>


<style lang="scss">

@charset "utf-8";
@media only screen and (max-width: 768px) {
    #app{
      width:1200px;
    }
}
.hezuo{
  // z-index: 999999;
  cursor: pointer;
  width: 64px;
  height: 210px;
  position: fixed;
  top: 30%;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .hezuotop{
    width: 64px;
    height: 127px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    .hezuotopsqhz{
        width: 64px;
        height: 64px;
        background: #FFA000;
        border-radius: 10px 10px 0px 0px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .hezuotopsqhzcontent{
          width: 32px;
          height: 40px;
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
        }
    }
    .hezuotopsqhz2{
        width: 64px;
        height: 64px;
        border-radius: 0 0 10px 10px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #848079;
    }
  }
  .hezuobottom{
      width: 64px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.16);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      font-size: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #848079;
  }
}
#app{
  position: relative;
  top:0;
  left: 0;
  height: 100%;
}
html{
  margin: 0 !important;
  padding: 0;
  // overflow-x: hidden;
}
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,input{
	margin: 0 !important;
	padding: 0;
}
ul,ol,li{
	list-style: none;
}
a{
	text-decoration: none;
}
img{
	vertical-align: middle;
}
.clear:after{
  content: '';
  display: block;
  clear: both;
}
</style>
