<template>
  <div class="bigbox">
    <div class="bigboxcontent">
     <a style="color:#fff" href="https://beian.miit.gov.cn/#/Integrated/index" target="_bank">版权所有Copyright©2005-2002中科星图瑞云科技有限公司皖ICP备20004392号-1</a> 
    </div>
  </div>
</template>

<script>
  export default {
    methods:{
      openline(){
        window.open("https://beian.miit.gov.cn/#/Integrated/index" ,'_bank')
      },
    }
  }
</script>

<style lang="scss" scoped>
.bigbox{
  // // width: 1903px;
  height:143px;
  margin: 0 auto;
  background-color: #282A2E;
  .bigboxcontent{
    cursor: pointer;
    padding-top: 50px;
    text-align: center;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 732px;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
}
</style>