import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueAMap from 'vue-amap';
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAMap);
Vue.use(MetaInfo)
VueAMap.initAMapApiLoader({
  key: '90ef4bd704adab8e569f5ec063c4773e',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
